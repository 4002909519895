<template>
  <div>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("优惠券") }}</div>
      </div>
      <el-tabs v-model="status_name" type="card" style="margin: 26px 26px 0" @tab-click="getData">
        <el-tab-pane :label="temporaryText" name="全部"></el-tab-pane>
        <el-tab-pane :label="$fanyi('使用记录')" name="使用记录"></el-tab-pane>
      </el-tabs>
      <!--      使用记录状态下的优惠券筛选-->
      <div class="flexAndCenter orderScreenContainer" v-show="status_name!=='全部'">
        <div class="elSelectContainer">
          <el-select v-model="form.discount_status" style="width:160px">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="orderKeywordsContainer flexAndCenter" style="margin-left: 26px">
          <!--          <div class="fontSize14 color333" style="width:71px">{{ $fanyi("订单号") }}：</div>-->
          <el-input v-model="form.keywords" style="width: 200px;" :placeholder="$fanyi('请输入订单号')"/>
        </div>
        <div class="orderScreenBtnListContainer flexAndCenter" style="margin-left:26px">
          <div @click="getData(form)">{{ $fanyi("筛选") }}</div>
          <div @click="reset">{{ $fanyi("重置") }}</div>
        </div>
      </div>
      <!--      使用记录状态下的优惠券列表-->
      <div class="dataBody">
        <ul class="head flexAndCenter" v-show="status_name==='使用记录'">
          <li class="oNO"><span class="tableHeadCon">No</span></li>
          <li class="orderNumber">
            <span class="tableHeadCon" :title="$fanyi('券编码')">{{ $fanyi("券编码") }}</span>
          </li>
          <li class="numberOfCoupon">
            <span class="tableHeadCon" :title="$fanyi('券类别')">{{ $fanyi("券类别") }}</span>
          </li>
          <li class="quota">
            <span class="tableHeadCon" :title="$fanyi('额度/可用次数')">{{ $fanyi("额度/可用次数") }}</span>
          </li>
          <li class="dateOfValidity">
            <span class="tableHeadCon" :title="$fanyi('有效日期')">{{ $fanyi("有效日期") }}</span>
          </li>
          <li class="timeOfUse">
            <span class="tableHeadCon" :title="$fanyi('使用时间')">{{ $fanyi("使用时间") }}</span>
          </li>
          <li class="statusOfUse">
            <span class="tableHeadCon" :title="$fanyi('使用状态')">{{ $fanyi("使用状态") }}</span>
          </li>
          <li class="orderNo">
            <span class="tableHeadCon" :title="$fanyi('订单号')">{{ $fanyi("订单号") }}</span>
          </li>
        </ul>
        <ul class="obody flexAndCenter"
            v-for="(item, index) in tableData"
            :key="$fanyi('表格') + index" v-show="status_name==='使用记录'">
          <!--          No-->
          <li class="oNO"><span class="tableHeadCon">{{ index + 1 }}</span></li>
          <!--         券编码-->
          <li class="orderNumber">
            <span class="tableHeadCon">{{ item.sn }}</span>
          </li>
          <!--         券类别-->
          <li class="numberOfCoupon flexAndCenterAndCenter">
            <div :class="item.type_name==='代金券'?'voucher':'coupons'">{{
                item.type_name === '代金券' ? $fanyi("代金券") : item.type_name === '折扣券' ? $fanyi("折扣券") : $fanyi("优惠券")
              }}
            </div>
          </li>
          <!--         额度/可用次数-->
          <li class="quota">
            <span class="tableHeadCon">{{ item.discounts_amount_jpy }}</span>
          </li>
          <!--         有效日期-->
          <li class="dateOfValidity">
            <span class="tableHeadCon">{{ item.effective_date }}~{{ item.expiry_date }}</span>
          </li>
          <!--         使用时间-->
          <li class="timeOfUse">
            <span class="tableHeadCon">{{ item.use_datetime }}</span>
          </li>
          <!--         使用状态-->
          <li class="statusOfUse">
            <span class="tableHeadCon">{{ $fanyi(item.status_name) }}</span>
          </li>
          <!--         订单号-->
          <li class="orderNo">
            <span class="tableHeadCon">{{ item.use_porder_sn !== '' ? item.use_porder_sn : item.use_order_sn }}</span>
          </li>
        </ul>
        <!--        全部优惠券列表-->
        <div v-show="status_name!=='使用记录'" class="allCouponListContainer">
          <div v-for="(item, index) in tableData" :key="index"
               :class="item.type_name==='优惠券'?'discountsCouponContainer':item.type_name==='折扣券'?'discountCouponContainer':'voucherContainer'">
            <!--        优惠券类别-->
            <div v-if="item.type_name!=='折扣券'" :style="item.type_name==='优惠券'?'color: #BE6F35;':'color:#fff'"
                 class="typeName">
              · {{ $fanyi(item.type_name) }} ·
            </div>
            <div v-else class="typeName" style="color: #fff;margin-top: -2px">
              {{ item.discounts_amount_jpy == "0" ? $fanyi(item.type_name) : '金券' }}
            </div>
            <!--        优惠券效果或优惠券金额展示-->
            <div v-if="item.type_name==='优惠券'" class="discountsCouponText">
              手数料無料
            </div>
            <div v-else :style="item.type_name === '折扣券'?'margin-top: 10px;':''" class="voucherText">
              <div>{{
                  item.type_name === '折扣券' && item.discounts_amount_jpy == "0" ? 100 - item.discounts_rate : item.discounts_amount_jpy
                }}
              </div>
              <div>{{ item.type_name === '折扣券' && item.discounts_amount_jpy == "0" ? '%割引' : '円' }}</div>
            </div>
            <!--优惠券文字-->
            <div :style="item.type_name === '折扣券'?'background: #2748B5;':''" class="couponText">
              {{ item.type_name === '优惠券' ? '注文書' : '配送依頼書' }}
            </div>
            <div v-if="item.type_name === '折扣券'" class="discountCouponDataContainer">
              <span :title="item.supportLogisticsName" class="u-line logisticsName">{{
                  item.supportLogisticsName
                }}</span><br>
              <!--              重量{{ Number(item.min_weight) }}KG以上利用可能<br>-->
              <!--              金額{{ item.min_consumption_jpy }}円以上利用可能-->
              課金重量≥{{ Number(item.min_weight) }}kg<br>
              金額≥{{ item.min_consumption_jpy }} 円
            </div>
            <!--        sn码-->
            <div
                :style="item.type_name==='优惠券'?'color: #BE6F35;':item.type_name === '折扣券'?'color:#fff;margin-top:10px':'color:#fff;margin-top:16px'"
                class="couponNo">
              NO.{{ item.sn }}
            </div>
            <!--            到期时间-->
            <div v-if="sameDate(item.expiry_date)" :style="item.type_name==='优惠券'?'color: #BE6F35;':'color:#fff'"
                 class="periodOfValidityText">
              {{ $fanyi("今日到期") }}
            </div>
            <div v-else class="periodOfValidityText" :style="item.type_name==='优惠券'?'color: #BE6F35;':'color:#fff'">
              {{ $fanyi("有效期") }}
            </div>
            <div class="periodOfValidity" :style="item.type_name==='优惠券'?'color: #BE6F35;':'color:#fff'">
              {{ item.effective_date }}~{{ item.expiry_date }}
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 30px 0;justify-content: center" class="flexAndCenter" v-show="status_name !== '全部'">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {sameDate} from "@/utlis/date";
import Dialog from "../../../../components/public/Dialog";

export default {
  comments: {
    Dialog
  },
  data() {
    return {
      form: {
        discount_status: undefined,
        keywords: '',
        page: 1,
        pageSize: 1000
      },
      options: [{
        value: 2,
        label: '已使用'
      }, {
        value: -1,
        label: '已过期'
      }],
      // -1已过期，0待发放，1待使用，2已使用
      status_name: '全部',
      total: 0,
      temporaryText: this.$fanyi('全部优惠券') + '（0）',
      tableData: [],
      timeValue: [],
    };
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
      this.tableData = [];
      if (this.status_name === '全部') {
        this.form = {
          page: 1,
          pageSize: 1000
        };
        this.$api.couponGet(this.form).then((res) => {
          res.data.data.forEach((item) => {
            if (item.type_name === '折扣券') {
              item.supportLogisticsName = '';
              item.logistics_group.forEach((logistics_group_item, index) => {
                if (index === item.logistics_group.length - 1) {
                  item.supportLogisticsName += logistics_group_item.logistics_name
                } else {
                  item.supportLogisticsName += logistics_group_item.logistics_name + ','
                }
              })
            }
          })
          this.tableData = res.data.data;
          this.temporaryText = this.$fanyi('全部优惠券') + `（${res.data.data.length}）`;
          this.total = res.data.total
        });
      } else {
        this.form.pageSize = 10;
        this.$api.couponRecord(this.form).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
        });
      }
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData(this.form)
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form)
    },
    //重置
    reset() {
      this.form.discount_status = '';
      this.form.keywords = '';
      this.getData();
    },
    sameDate(time) {
      return sameDate(time);
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";


/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}


#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .orderScreenContainer {
    margin-left: 26px;
    padding-bottom: 15px;
    margin-right: 26px;
    border-bottom: 1px solid #DFDFDF;

    .elSelectContainer {
      /deep/ .el-input__inner {
        width: 160px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
      }


      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }

    .orderKeywordsContainer {
      /deep/ .el-input__inner {
        width: 200px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
      }
    }

    .orderTimeContainer {
      /deep/ .el-input__inner {
        width: 160px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        padding-left: 13px;
        padding-right: 0;
      }

      /deep/ .el-date-editor {
        .el-input__prefix {
          display: none;
        }
      }
    }

    .orderScreenBtnListContainer {
      div {
        width: 76px;
        height: 30px;
        //background: #B4272B;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
      }

      div:first-child {
        color: #fff;
        background: #B4272B;
        margin-right: 10px;
      }

      div:last-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }

  }

  .dataBody {
    margin: 20px 26px 0;

    .oNO {
      width: 50px;
    }

    .orderNumber {
      width: 160px;
    }

    .numberOfCoupon {
      width: 70px;
      //<div v-if="item.value2===1" class="voucher"></div>
      //<div v-else class="coupons"></div>
      .voucher, .coupons {
        width: 60px;
        height: 26px;
        border-radius: 3px;
        line-height: 26px;
        text-align: center;
        font-size: 14px;
      }

      .coupons {
        background: #EFF1F9;
        color: #2742B4;
      }

      .voucher {
        background: #FAF2F2;
        color: #B4272B;
      }
    }

    .statusOfUse {
      width: 72px;
    }

    .orderNo {
      width: 140px;
    }

    .timeOfUse {
      width: 170px;
    }

    .dateOfValidity {
      width: 310px;
    }

    .quota {
      width: 130px;
    }

    .datePresentation {
      width: 170px;
    }

    .upLoad {
      width: 90px;
    }

    .orderStatus {
      width: 140px;
    }

    .operation {
      width: 112px;
    }

    .head {
      justify-content: center;
      background: #F0F0F0;
      font-size: 14px;
      color: #333;
      height: 56px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
    }

    .obody {
      text-align: center;
      height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }

    .allCouponListContainer {
      display: flex;
      flex-wrap: wrap;

      .discountsCouponContainer, .voucherContainer, .discountCouponContainer {
        width: 190px;
        height: 270px;
        margin: 0 8px 8px 0;
        padding: 10px 0 0;
        box-sizing: border-box;
      }

      .voucherContainer {
        background: url("../../../../assets/voucherBg.png") no-repeat;
        background-size: 100% 100%;
      }

      .discountsCouponContainer {
        background: url("../../../../assets/discountCouponBg.png") no-repeat;
        background-size: 100% 100%;
      }

      .discountCouponContainer {
        background: url("../../../../assets/logisticsDiscountTicketBg.png") no-repeat;
        background-size: 100% 100%;
      }

      .borderIcon {
        margin-top: 9px;
        width: 20px;
        height: 2px;
        background: #FFFFFF;
      }

      .typeName {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
      }

      .discountsCouponText {
        margin-top: 45px;
        text-align: center;
        color: #B4272D;
        font-weight: bold;
        font-size: 24px;
      }

      .discountCouponDataContainer {
        font-size: 12px;
        color: #fff;
        text-align: center;

        span {
          display: inline-block;
          width: 160px;
          cursor: pointer;
        }
      }

      .voucherText {
        display: flex;
        margin-top: 52px;
        justify-content: center;

        div:first-child {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          margin-right: 4px;
        }

        div:last-child {
          font-weight: bold;
          color: #fff;
          margin-top: 6px;
        }
      }

      .couponText {
        padding: 6px 7px;
        border-radius: 6px;
        background: #B5272D;
        color: #FFFFFF;
        font-size: 12px;
        margin: 8px auto 0;
        max-width: 76px;
        text-align: center;
      }


      .periodOfValidityText {
        margin-top: 22px;
        text-align: center;
        color: #BE6F35;
        font-weight: 300;
        font-size: 12px;
        margin-bottom: 8px;
      }

      .periodOfValidity {
        color: #BE6F35;
        font-size: 12px;
        text-align: center;
      }

      .couponNo {
        color: #FFFFFF;
        font-size: 10px;
        text-align: center;
        margin-top: 24px;
      }

      .couponContainer:nth-child(5n) {
        margin: 0 0 8px 0;
      }
    }
  }
}
</style>
